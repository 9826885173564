@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  @media (min-width: 400px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.card {
  box-shadow: 0 5px 12px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5em 1.5em 2.5em 1.5em;
}

.title {
  @apply text-2xl font-bold;
}

.subtitle {
  @apply text-lg font-bold;
}

.h3 {
  @apply font-bold;
}

/* This is to remove the autofill color from the input */
/* because it breaks the design and hides the label in some cases */
input:is(:-webkit-autofill, :autofill),
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  /* Revert background color */
  transition: background-color 5000s ease-in-out 0s;
}
