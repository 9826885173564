.button-blue {
  font-size: 1rem;
  padding: 0.6875rem 1.5rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  text-decoration: none;
  background-color: #4582ec;
  border-color: #4582ec;
  color: white;
  display: inline-block;
  margin-top: 1rem;
  width: auto;
}

.button-blue:hover {
  color: #fff;
  background-color: #226be8;
  border-color: #1863e6;
}

.button-blue:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.button {
  font-size: 1rem;
  padding: 0.6rem 1rem 0.4rem 1rem; /* 40px height */
  line-height: 1.5;
  border-radius: 0.25rem;
  text-decoration: none;
  display: inline-block;
  width: auto;
}

.button-success {
  background-color: #02b875;
  border-color: #02b875;
  color: #fff;
}

.button-outlined-purple {
  background: white;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  color: var(--clr-ps-purple) !important;
}

.button-outlined-blue {
  background: white;
  border: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  color: #45b3de !important;
}

.button-link {
  background: none;
  border: none;
  text-decoration: underline;
  color: black;
  padding: 0;
}

.button-link:disabled {
  color: #999999 !important;
  cursor: not-allowed !important;
}

.button-link:focus,
.button-link:hover {
  color: #7f7f7f;
}

.center-vertically {
  align-items: center;
  display: flex;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

p.mb-4 {
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-16 {
  margin-top: 4rem !important;
}

.my-0 {
  margin-bottom: 0;
  margin-top: 0;
}

.my-2 {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.my-4 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.my-8 {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.my-16 {
  margin-bottom: 4rem;
  margin-top: 4rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-auto {
  margin-left: auto;
}

h1 {
  color: inherit;
}

.text-inherit {
  color: inherit !important;
}

.font-bold {
  font-weight: bold;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.text-sm {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
}

.text-lg {
  font-size: 1.125rem; /* 18px */
  line-height: 1.75rem; /* 28px */
}

.text-xl {
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
}

.text-2xl {
  font-size: 1.5rem !important; /* 24px */
  line-height: 2rem !important; /* 32px */
}

.text-3xl {
  font-size: 1.875rem; /* 30px */
  line-height: 2.25rem; /* 36px */
}

.text-4xl {
  font-size: 2.25rem; /* 36px */
  line-height: 2.5rem; /* 40px */
}

.italic {
  font-style: italic;
}

.link {
  border: none;
  background: none;
  cursor: pointer;
  color: inherit;
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.\!px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.py-0.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-1.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-2.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-3.5 {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.py-52 {
  padding-top: 13rem;
  padding-bottom: 13rem;
}

.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.py-60 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.py-72 {
  padding-top: 18rem;
  padding-bottom: 18rem;
}

.py-80 {
  padding-top: 20rem;
  padding-bottom: 20rem;
}

.py-96 {
  padding-top: 24rem;
  padding-bottom: 24rem;
}

.button-gradient {
  font-weight: bold;
  color: white;
  border-radius: 0.375rem;
  border: none;
  background-image: linear-gradient(262deg, #ac00bd, #53c9f7);
  text-decoration: none;
}

.button-gradient:hover {
  filter: brightness(1.1);
}

.button-gradient:active {
  filter: brightness(1.2);
}

.button-gradient:disabled {
  filter: opacity(0.8);
}

.purple-pink-gradient {
  background-image: linear-gradient(to left, #ff3867, #ac00bd);
}

.blue-purple-gradient {
  background: linear-gradient(250deg, #ac00bd, #53c9f7) !important;
}

.badge {
  display: inline-block;
  border-radius: 1rem;
  padding: 0.2rem 0.5rem 0.1rem 0.5rem;
}

.badge.blue-purple-gradient {
  color: white;
}

.badge-small {
  display: inline-block;
  font-size: 0.9rem;
  border-radius: 1rem;
  padding: 0.2rem 0.5rem 0.1rem 0.5rem;
}

.text-white {
  color: white;
}

.text-black {
  color: black;
}

.disclaimer {
  color: #999999;
}

.w-full {
  width: 100%;
}

@media (max-width: 768px) {
  .max-md\:hidden {
    display: none;
  }
}

@media (max-width: 1024px) {
  .max-lg\:hidden {
    display: none;
  }
}
