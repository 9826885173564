.mapboxgl-popup-content {
  padding: 0px;
}
.mapboxgl-popup-tip {
  display: none !important;
}

.mapboxgl-popup {
  z-index: 99999999 !important;
}
