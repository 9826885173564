.marker {
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background: transparent;
  border-radius: 9px;
  transition:
    width 100ms ease,
    height 100ms ease;
  z-index: auto;
}

.marker.wide {
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  z-index: 9999999 !important;
  background-color: rgba(255, 56, 103, 0.3);
}

.marker > div {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  border: 2px solid white;
  background-color: rgb(255, 56, 103);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.marker:hover > div,
.marker.wide > div {
  box-shadow: none !important;
}

.marker.wide > div {
  border: none;
}
